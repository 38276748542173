import { Divider } from './divider'

export const Unique = (props) => {
  return (
    <div id='unique'>
      <Divider />
      <div className='ui-container'>
      <p id="uniqueQuote" className="quotes">&quot; Not all diamonds marketed as Hearts &amp; Arrows are equal as there is no universally agreed standard, many merchants take wide liberties in assigning the H&amp;A designation...we do not. &quot;</p>
        <div className='text-center'>
        <p id="uniqueTitle" className="title grow">What Really Sets Us Apart</p>
              <div className="title-divider"></div>
              </div>
              <div  className="text-justify uniqueBody">
              <p className='marginBetweenP'>Our diamonds are cut to maximize brilliance, while others are cut to maximize carat weight - the
difference matters.</p>
         </div> 
         <div className="uniqueBody">
           <p className='marginBetweenP'>We understand an excellent polish and symmetry alone does not suffice for the level of standard
that we deem as Excellent. Our competitors generally settle on cutting for GIA Excellent or some
other premium cut to maximize their own profit but at   {props.data ? props.data.companyName : 'Loading'} we are willing to commit the time
and the weight loss necessary to produce a super ideal H&amp;A diamond because we understand
quality trumps all and that a diamond embeds within itself a dozens of other feelings for both our
clients and our client’s clients.
These are the grading standards used by   {props.data ? props.data.companyName : 'Loading'} to determine whether a diamond qualifies to be
sent onwards to our clients:</p>
        </div>
        <div className='row uniqueBody'>
            <div className="section text-center col-xs-12 col-md-12 col-lg-12" style={{paddingTop:'1em', paddingBottom:'1em'}}>
            <h4>This is our definition of the perfect Hearts and Arrows ideal cut diamond.</h4>
          <img src='img/hearts-and-arrows.png' className="center heartsImgBorder" alt='' />{' '}
          </div>
        
        <div className='col-xs-12 col-md-6 col-lg-6'>
        <div className='unique-text list-style'>
          <h3>Hearts</h3>
            <ul>
              <li>Every heart shape is perfectly aligned and symmetrical.</li>
              <li>Each heart shape is of equal intensity. </li>
              <li>Shoulders are pointed (squared-off) by the crown facets.</li>
              <li>There is no separation at the cleft.</li>
              <li>There is even separation between the arrowhead (v-shape) and the tip of each heart</li>
            </ul>
         
          </div>
          </div>
          <div className='col-xs-12 col-md-6 col-lg-6'>
          <div className='unique-text list-style'>
         
          <h3>Arrows</h3>
            <ul>
               <li>All the arrows are of equal size and are aligned.</li>
               <li>Each arrow is of equal intensity.</li>
               <li>No side effects, flagging and clustering.</li>
               <li>Triangles are evenly placed and are not scattered.</li>
               <li>All points of the arrowheads must extend to the girdle.</li>
            </ul>
          </div>
          </div>
         </div>
    
    <div className='row col-xs-12 col-md-12 col-lg-12 uniqueBody'>
      <p className='marginBetweenP'>Being aware that many merchants out there knowingly or unknowingly allow 
imperfections to pass off as an ideal cut H&amp;A diamond is truly a shame. </p>
<p className='marginBetweenP'>We, at   {props.data ? props.data.companyName : 'Loading'}, are
committed to create a product that is unparalleled in our time by removing any and all
imperfections that might appear in a diamond. Our commitment to perfection goes just beyond
our product, in fact our commitment to perfection lies in our excellent ability to serve our clients
with integrity, transparency, trust and wisdom.</p>
<p className='marginBetweenP'>Each of {props.data ? props.data.companyName : 'Loading'} diamonds are crafted by our extremely skilled in-house craftsmen which gives us
the ability to maintain firm control over the quality of each and every single one of our polished
diamonds. All   {props.data ? props.data.companyName : 'Loading'} stones produced at our in-house factory produce a high level of Fire,
Scintillation, Brilliance which can be said to be a quantifiable proof of how magnificent our
diamonds are.</p>
    </div>
      </div>
    </div>
  )
}
