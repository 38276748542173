import { Divider } from './divider'

export const Services = (props) => {
  return (
    <div id='services' className='text-center'>
      <Divider />

      <div className='ui-container'>
        <p id="compositionQuote" className="quotes">&quot; The level of care &amp; attention we put into our diamonds is visible even to the naked eye. &quot;</p>
        <div>
          <p id="compositionTitle" className="title grow">Our Composition</p>
          <div className="title-divider"></div>
        </div>
        <div id="compositionBody">
          <div className='row'>
            <div className='col-md-12 col-lg-12  text-justify'>
              <p className='marginBetweenP'>
                We specialize in creating the ideal Hearts and Arrows cut with very precise parameters and
                specifications.
              </p>
              <p className='marginBetweenP'>Each diamond that we manufacture can be customized to provide our clients with the highest value.</p>

              <p className='marginBetweenP'>Our H&amp;A Diamonds are unmatched in precision, brilliance, fire and sparkle allowing our
                magnificent everlasting gems to appear <b> larger and whiter </b> than the diamonds created by our
                competitors. Even after decades of wear - not only does this make us look good, <b>it makes you look
                  good</b> in the eyes of the wearer of your jewelry because you chose to partner with perfection.</p>


              <p className='marginBetweenP'>Our diamonds are perfect because of the high attention to detail that we put when it comes to
                making sure that each individual heart and arrow design is <b>perfectly aligned</b>, <b>symmetrical</b> and of
                the <b>same intensity</b>. We put extra care into making sure our hearts are uniform and our
                arrows extend from the table to the end to create the best possible diamond.
              </p><p className='marginBetweenP'>Each {props.data ? props.data.companyName : 'Loading'} diamond offers edge-to-edge brilliance with literal explosions of white lustre, even in low light
                conditions. </p>
              <p className='marginBetweenP'>Our diamonds follow these specifications and can be customized.</p>



            </div>
            <div className='col-lg-12 col-md-12 col-sm-12 compositionBody'>
              <div className='col-md-6'>
                <div>
                  <p>OUR IDEAL CUT SPECIFICATIONS</p>
                </div>
                <div className='list-style services-text'>
                  <ul>
                    <li className="list-group-item">Sizes: -2, +2-6.5, +6.5-9, +9-11, +11-12, +12-14, +14-15.5</li>
                    <li className="list-group-item">Pointers: 0.20 ~ 0.23ct, 0.24 ~ 0.29ct, 0.30 ~ 0.39ct, 0.50 ~ 0.59ct</li>
                    <li className="list-group-item">Colour: D, E, F, G, H, I, J, K </li>
                    <li className="list-group-item">Clarity: IF, VVS, VS</li>
                    <li className="list-group-item">Certifications: GIA, Non-cert, and Sarine</li>
                  </ul>
                </div>
              </div>
              <div className='col-md-6'>
                <div>
                  <p>OUR IDEAL CUT DEFINITION</p>
                </div>
                <div className='list-style services-text'>
                  <ul>
                    <li className="list-group-item">The cut should be so defined that one can see the symmetric reflection of light from each angle.</li>
                    <li className="list-group-item">The higher contrast patterning gives diamond an excellent look.</li>
                    <li className="list-group-item">Superior fire, brilliance and Scintillation allows it to look bigger and perfect.  </li>

                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-12">
            <p className='marginBetweenP'>Our specially crafted H&amp;A diamonds are for
              companies that serve the very best and provide perfection, excellence and brilliance in all parts
              of their business.</p>
          </div>
        </div>
      </div>
    </div >

  )
}
