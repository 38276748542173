export const Navigation = (props) => {
  return (
    <nav id='menu' className='ui-navbar ui-navbar-default ui-navbar-fixed-top'>
      <div className="menuPadding">
        <div className='ui-navbar-header'>
          <button id="ui-nav-toggle-button"
            type='button'
            className='ui-navbar-toggle ui-collapsed'
            data-toggle='collapse'
            data-target='#bs-example-navbar-collapse-1'
          >
            {' '}
            <span className='ui-sr-only'>Toggle navigation</span>{' '}
            <span className='icon-bar'></span>{' '}
            <span className='icon-bar'></span>{' '}
            <span className='icon-bar'></span>{' '}
          </button>
          <a id="navLogo" className='ui-navbar-toggle ui-collapsed' href='#page-top' style={{float:'left', border:'0'}}>
           <img src="../img/vvdlogo.png" width="70" alt="VVDDiam"/>
          </a>
        </div>

        <div
          className='collapse ui-navbar-collapse pullRight fade-in'
          id='bs-example-navbar-collapse-1'>
          <ul id="menuUl" className='nav ui-navbar-nav navbar-right navbar-gradient'>
          
            <li>
              <a href='#about' id="aboutUsLink" className='page-scroll grow'>
               KNOW US
              </a>
            </li>
            <li>
              <a href='#services' id="compositionLink" className='page-scroll grow'>
                OUR COMPOSITION
              </a>
            </li>
            <li>
              <a href='#unique' id="uniqueLink" className='page-scroll grow'>
               WHAT SETS US APART
              </a>
            </li>
            <li>
              <a href='#contact' id="contactLink" className='page-scroll grow'>
                CONTACT
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}
