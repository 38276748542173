import { Divider } from './divider'
export const About = (props) => {
  return (
    <div id='about'>
     <Divider/>
      <div className='ui-container'>
        <p id="aboutUsQuote" className="quotes">&quot; Jewelry &amp; diamond commerce is a hereditary &amp; generation long pursuit, where from the manufacturer to the customer, trust is pivotal. &quot;</p>
        <div className='row'>
        <div className='col-xs-12 col-md-12 col-lg-12'>
            <div className='about-text'>
              <p id="aboutUsTitle" className="title grow">Know Us</p>
              <div className="title-divider"></div>
              <div id="aboutUsBody" className="text-justify">
              <p className='marginBetweenP'>Providing excellence to customers for <strong> over 20 years, </strong>  {props.data ? props.data.companyName : 'Loading'} has pushed the boundaries of
perfection by redefining the Ideal Hearts and Arrows. It is our strive for perfection that guides
us, where everything we do reflects our persistence and commitment towards the remarkable.</p>
<p className='marginBetweenP'>Based in India and Taiwan we manufacture scientifically-measurable near-perfect to perfect
ideal cut Hearts and Arrows diamonds from the rough.
</p>
   
<p className='marginBetweenP'> Each   {props.data ? props.data.companyName : 'Loading'} diamond is imagined, cut,
polished and graded to the most <strong>stringent standards </strong> by our in-house craftsmen whose level of
skill remain unmatched. Producing such pieces of elegance takes us <strong>twice as long </strong>to create as
compared to the average H&amp;A diamond, however for us it is a necessity as it allows us <strong>to procure and provide perfection </strong> .</p>
<img src='img/about.png'  alt='' className="center" />{' '}
         <p className='marginBetweenP'>Our focus on the extreme allows our diamonds to continually beget value for our
customers while ensuring <strong>complete client satisfaction</strong>.</p>
<p className='marginBetweenP'>All   {props.data ? props.data.companyName : 'Loading'} diamonds are 100% naturally sourced and go through extensive CVD check.</p>
</div>
        </div>
        </div>
        </div>
           
       </div>
        </div>
      )
}
