export const Header = (props) => {
  return (
    <header id='header' >
      <div className='intro'>
      {/* <div className="snowflakes" aria-hidden="true">
  <div className="snowflake">
  ❅
  </div>
  <div className="snowflake">
  ❅
  </div>
  <div className="snowflake">
  ❆
  </div>
  <div className="snowflake">
  ❄
  </div>
  <div className="snowflake">
  ❅
  </div>
  <div className="snowflake">
  ❆
  </div>
  <div className="snowflake">
  ❄
  </div>
  <div className="snowflake">
  ❅
  </div>
  <div className="snowflake">
  ❆
  </div>
  <div className="snowflake">
  ❄
  </div>
  <div className="snowflake">
  ❆
  </div>
  <div className="snowflake">
  ❄
  </div>
</div> */}

<div id="starshine">
    <div class="template shine"></div>
</div>
   
       <div className='overlay'>
          <div id="headerText">
            <div className=''>
            <div className='col-md-8 col-md-offset-5 intro-text fade-in'>
            <img src="../img/vvdlogo.png" className="center" style={{width:'20%'}} alt="VVDDiam"/>
            <div className="clearFix"></div>
            <p className="companyName typewriter">
             <span className="gradientW">We</span> are <span className='heading'>  {props.data ? props.data.companyName : 'Loading'}</span>.</p>

             <div className="clearFix"></div>
               <p className="tagLine">Our diamonds enable you to serve clients that demand and deserve 
               <span className='colorGold'> perfection</span>,
                <span className='colorGold'> excellence</span> and
                <span className='colorGold'> brilliance</span> in all aspects of their life.</p> 
             
                </div>
            </div>
            
          </div>
        </div>
      </div>
    </header>
  )
}
