import React from "react";
export const Values = () => {
  return (
    <div class="containerVal promise">
     
  <div class="card-space">
    <div class="card">
      <div class="face front">
        <i class="fa fa-bullseye" style={{fontSize:'5rem'}}></i>
        <h1>Mission</h1>
       </div>
      <div class="face back">
        <p>To provide our clients with perfection, excellence and brilliance in the form of our
diamonds.</p>
      </div>
    </div>
  </div>
  <div class="card-space">
    <div class="card">
      <div class="face front">
        <i class="fa fa-eye" style={{fontSize:'5rem'}}></i>
        <h1>Vision</h1>
      </div>
      <div class="face back">
        <p>To be the leading global supplier of the ideal Hearts and Arrows cut in naturally sourced diamonds.</p>
      </div>
    </div>
  </div>
  <div class="card-space">
    <div class="card">
      <div class="face front">
        <i class="fa fa-diamond" style={{fontSize:'5rem'}}></i>
        <h1>Values</h1>
      </div>
      <div class="face back">
        <p> Trust, Ethics, Integrity, and Transparency within our clients and their end user always.</p>
      </div>
    </div>
  </div>
    </div>
  )
}
